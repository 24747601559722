.App {
  min-height: 100vh;
  align-items: center;
  background : #0b2335;
  height: 100%;
  text-align: center;
  color: white;
  background-image: url("../assets/images/bg-dark.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.wh-header {
  width: 100%;
  background-color: rgba(33, 36, 40, 0.55 ) !important;
}

.wh-footer {
  width:100%;
  background-color: rgba(33, 36, 40, 0.7) ;
 padding:20px 0;
 text-align: center;
 color:#747474;
 font-size: 14px;

}

.wh-nft-list-text {
  padding-bottom: 1%;
}
.wh-nft-list {
  padding-top: 2%;
  min-height: 100vh;
}

.info-style{
  text-decoration: none;
  /* padding-top: 5px; */
  background-color: rgb(230, 230, 230);
  border-radius:  100%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555555; 
}
.info-style:hover{
  text-decoration: none;
  /* padding-top: 5px; */
  background-color: rgb(110, 110, 110);
  border-radius:  100%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d6d6d6; 
}

.wh-top-cell{
  font-weight: 600 !important;
}

.wh-grid{
  padding-top: 10px;
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 50px;
}

.wh-link-card:hover{
  opacity: 0.7;
}

.wh-light-text{ 
  color: rgb(197, 197, 197);
}

.wh-light-text:hover{
  color: #ffffff; 
}

.single-nft{
  min-height: 100vh;
  overflow: hidden;
  color: white;
  background: #082032; 
  background-image: url("../assets/images/bg-dark.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.App-particles__container {
  overflow: hidden;
  position: absolute;
  z-index: 0;
}
.App-particles__container canvas {
  transition: 0.2s all ease-in-out;
  z-index: 0;
  height: 100%;
  width: 100%;
}

.wh-search-icon{
  float: right;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wh-key-icon{
  height: 100%; 
  float: left;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wh-key-icon:hover{
  color: #000000;
}

.wh-button{
  background-color: #ffffff !important;
  color: #1b1b1b !important; 
}
.wh-button:hover{
  opacity: 0.6 !important;
} 

/* single nft */

.single-nft-top-wrapper{
  /* margin: 2% 10%  !important; */
  /* margin-bottom: 6% !important; */
}
.single-nft-top-wrapper
.wh-single-nft-breadcrumb{
  margin: 2% 0 ;
}

.wh-gradient-pink-blue {
  background: linear-gradient(to right,#12c2e9,#c471ed,#f64f59);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.wh-weedheads-text{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.rarity-pill{
  background: rgba(48, 19, 120, 0.96);
  border: none;
  color: whitesmoke;
  padding: 7px 17px;
  font-size: small;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}

.rarity-pill-text{
  font-size: small;
  font-weight: bold;
}


.wh-home-description{
  letter-spacing: -0.2px;
  line-height: 1.69;
  font-size: 20px;
  padding:5px 30%;
  text-shadow:1px 1px #006400;
}



@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .wh-home-description{
    letter-spacing: -0.2px;
    line-height: 1.69;
    font-size: 18px;
    padding:5px 10%;
    word-wrap: normal;
    text-shadow:1px 1px #006400;
  }
}


@media only screen and (min-width: 600px) {
  /* For tablets: */
  
}